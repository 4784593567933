import {
  defer,
  type LinksFunction,
  type MetaFunction,
  type LoaderArgs,
  type AppLoadContext,
} from '@shopify/remix-oxygen';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useCatch,
  useLoaderData,
  useMatches,
  useNavigation,
  useLocation,
} from '@remix-run/react';
import {ShopifySalesChannel, Seo} from '@shopify/hydrogen';
import {Layout} from '~/components';
import {GenericError} from './components/GenericError';
import {NotFound} from './components/NotFound';
import styles from './styles/app.css';
import favicon from '../public/favicon.svg';
import {seoPayload} from '~/lib/seo.server';
import {DEFAULT_LOCALE, parseMenu, type EnhancedMenu} from './lib/utils';
import invariant from 'tiny-invariant';
import {Shop, Cart} from '@shopify/hydrogen/storefront-api-types';
import {useAnalytics} from './hooks/useAnalytics';
import {ExternalScripts} from 'remix-utils';
import type {
  Customer,
  Maybe,
  Metafield,
} from '@shopify/hydrogen/storefront-api-types';
import {redirect} from '@shopify/remix-oxygen';

//components
import LoadingScreen from 'components/global/LoadingScreen';
import Module from 'components/modules/Module';

//domains
import {getLangagueCodeForSanityQuery, isTWSite} from 'domains/sanity';
import {RootQueryRes, useRootQuery} from 'domains/root';
import {getExchangeRates, getExchangeRatesMap} from './domains/currency';
import type {MarselloData} from 'components/global/MarselloModal';
import {getCustomerMetafields} from 'domains/account';
import type {SanityModule} from 'domains/modules';
import React from 'react';

//translation
import translation from 'translations/global.json';

//data
import {currencyDetails} from '~/data/currencies';

//cookie
import {
  hmkCurrency,
  getSession,
  commitSession,
  previousLangSession,
} from './cookie.server';
import {routeLocale} from './domains/global';
import {I18nLocale} from './lib/type';

import {geolocation} from '@vercel/edge';

export const links: LinksFunction = () => {
  return [
    {rel: 'stylesheet', href: styles},
    {
      rel: 'preconnect',
      href: 'https://cdn.shopify.com',
    },
    {
      rel: 'preconnect',
      href: 'https://shop.app',
    },
    {
      rel: 'icon',
      type: 'image/svg+xml',
      href: 'https://cdn.shopify.com/s/files/1/0470/6526/8386/files/website-logo_32x32.png?v=1628839049',
    },
    {
      rel: 'preconnect',
      href: 'https://fonts.gstatic.com',
      crossOrigin: 'anonymous',
    },
    {
      rel: 'preload',
      href: 'https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap',
      as: 'style',
    },
    // {
    //   rel: 'stylesheet',
    //   href: 'https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap',
    //   media: 'print',
    //   onLoad: "this.onload=null;this.removeAttribute('media');",
    // },
  ];
};

let scripts: any = () => {
  return [
    // {
    //   src: 'https://loox.io/widget/loox.js?shop=helmetking-0001.myshopify.com',
    //   crossOrigin: 'anonymous',
    //   async: 'true',
    // },
    // {
    //   src: 'https://www.googletagmanager.com/gtag/js?id=G-EKWP0X2TCX',
    //   crossOrigin: 'anonymous',
    //   async: 'true',
    // },
  ];
};

export let handle = {scripts};

export const meta: MetaFunction = () => ({
  charset: 'utf-8',
  viewport: 'width=device-width,initial-scale=1',
});

const {LRUCache} = require('lru-cache');
const cache = new LRUCache({
  max: 500,
  ttl: 1000 * 60 * 60 * 24,
});

export async function loader({request, context, params}: LoaderArgs) {
  const req_url = new URL(request.url);
  const req_url_host = req_url.host;

  //get and set cookie session
  const session = await getSession(request.headers.get('Cookie'));
  const previous_lang = await session.get('previous-lang');
  const currencyFromSession = await session.get('hmk-currency');
  const currencyTWFromSession = await session.get('hmk-tw-currency');

  const current_lang = params.lang ?? 'zh_tw';
  const is_lang_changed = previous_lang !== current_lang;
  session.set('previous-lang', current_lang);

  const selectedCurrency =
    context.storefront.i18n.country === 'TW'
      ? currencyTWFromSession ?? 'TWD'
      : currencyFromSession ?? 'HKD';

  if (context.storefront.i18n.country === 'TW') {
    session.set('hmk-tw-currency', selectedCurrency);
  } else {
    session.set('hmk-currency', selectedCurrency);
  }
  const cookie = await commitSession(session);

  const geo = geolocation(request);

  const requestingFrom = geo.country ?? 'HK';

  if (requestingFrom === 'TW') {
    if (req_url_host !== 'tw.helmetking.com') {
      return redirect('https://tw.helmetking.com');
    }
  } else {
    if (req_url_host === 'tw.helmetking.com' && requestingFrom !== 'TW') {
      return redirect('https://www.helmetking.com');
    }
  }

  //Sanity
  const languageCode = getLangagueCodeForSanityQuery(
    context.storefront.i18n.language,
  );
  let rootData: RootQueryRes;
  if (cache.has('rootData') && !is_lang_changed) {
    rootData = await cache.get('rootData');
  } else {
    rootData = await useRootQuery({
      ipAddressLocation: geo.country,
      languageCode,
    });
    await cache.set('rootData', rootData);
  }

  const [customerAccessToken, cartId, layout] = await Promise.all([
    context.session.get('customerAccessToken'),
    context.session.get('cartId'),
    getLayoutData(context),
  ]);

  let customer;
  let marsello;
  let metafields;

  if (customerAccessToken) {
    try {
      customer = await getCustomer(context, customerAccessToken);
      if (customer) {
        //gid://shopify/Customer/5460251312290
        const cSourceProvId = customer.id
          ? customer.id.split('gid://shopify/Customer/')[1]
          : '';
        const marselloRes = await fetch(
          `https://app.marsello.com/Portal/Widget/GetContent?aid=60e82c810880c008e8ff96ba&aprovid=60e82c7a0880c001ac0eae33&cSourceProvId=${cSourceProvId}&st=`,
        );
        marsello = (await marselloRes.json()) as MarselloData;

        const [customerWithMetafields, metafieldsError] =
          await getCustomerMetafields({customerId: customer.id});
        metafields = (customerWithMetafields as any)?.edges as Maybe<
          {node: Metafield}[]
        >;
      }
    } catch (error) {
      console.log(error);
    }
  } else {
    try {
      const marselloRes = await fetch(
        `https://app.marsello.com/Portal/Widget/GetContent?aid=60e82c810880c008e8ff96ba&aprovid=60e82c7a0880c001ac0eae33&st=`,
      );
      marsello = (await marselloRes.json()) as MarselloData;
    } catch (error) {
      console.log(error);
    }
  }

  const seo = seoPayload.root({
    shop: layout.shop,
    url: request.url,
    title: rootData.seo.title,
    description: rootData.seo.description,
  });

  const countryCode = isTWSite(request.url) ? 'TW' : 'HK';
  const baseCurrency =
    rootData.currencySettings.base_currencies
      .find((c: any) => c.country === countryCode)
      ?.currency?.split('-')[1] ?? 'HKD';

  const exchangeRates = getExchangeRates(
    rootData.currencySettings,
    baseCurrency,
  );
  const exchangeRatesMap = getExchangeRatesMap(exchangeRates);

  const wishlist =
    metafields && metafields.find((m) => m.node.key === 'wishlist')?.node;
  const selectedLocale =
    context.storefront.i18n.language.toLowerCase() as routeLocale;

  const RECAPTCHA_KEY =
    process.env.NODE_ENV === 'development'
      ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
      : process.env.RECAPTCHA_KEY;

  return defer(
    {
      marsello,
      // googleReviews,
      // facebookReviews,
      isLoggedIn: Boolean(customerAccessToken),
      layout,
      productSettings: rootData.productSettings,
      wishlist,
      translation: translation[selectedLocale],
      currency: {
        exchangeRates: exchangeRatesMap,
        baseCurrency,
        selectedCurrency,
        all_currencies: rootData.currencySettings.all_currencies.map((c) => {
          const currency = c.currency.split('-')[1];
          return {
            ...c,
            currency: currency,
            exchange_rates: c.exchange_rates.map((er) => ({
              ...er,
              base_currency: er.base_currency.split('-')[1],
            })),
            details: currencyDetails.find(
              (currencyDetail) =>
                currencyDetail.country === c.currency.split('-')[0],
            ),
          };
        }),
      },
      selectedLocale: context.storefront.i18n,
      cart: cartId ? getCart(context, cartId, request.url) : undefined,
      analytics: {
        shopifySalesChannel: ShopifySalesChannel.hydrogen,
        shopId: layout.shop.id,
      },
      seo,
      logo: rootData.logo,
      announcement: rootData.announcement,
      footer: rootData.footer,
      menu: selectedLocale === 'en' ? rootData.menu_en : rootData.menu_zh,
      featuredBrands: rootData.featuredBrands,
      allBrands: rootData.allBrands,
      sidebar: rootData.sidebarItems,
      business: rootData.business,
      emailSignUp: rootData.emailSignUp,
      notFoundPage: rootData.notFoundPage,
      categories: rootData.categories,
      req_url_host,
      ENV: {RECAPTCHA_KEY: '6LeoLxgpAAAAAE5Z-HyvQV4_DI0SNz6F0q6CMGLu'},
      ipAddressLocation: requestingFrom,
      // gaTrackingId: process.env.GA_TRACKING_ID ?? '',
    },
    {
      headers: {
        // 'Set-Cookie': cookie.serialize('hmk-currency', 'HKD'),
        'Set-Cookie': cookie,
      },
    },
  );
}

export default function App() {
  const data = useLoaderData<typeof loader>();
  const locale = data.selectedLocale ?? DEFAULT_LOCALE;
  const hasUserConsent = true;

  useAnalytics(hasUserConsent, locale);
  const location = useLocation();

  const navigation = useNavigation();

  //   React.useEffect(()=>{
  // if(typeof window !== 'undefined'){
  //   require('../public/looxReviews')
  // }
  //   }, [])

  React.useEffect(() => {
    if (typeof window !== 'undefined' && window.gtag && location) {
      window.gtag('config', 'G-EKWP0X2TCX', {
        page_path: location.pathname,
      });
    }
  }, [location]);

  React.useEffect(() => {
    if (data.req_url_host === 'en.helmetking.com') {
      window.location.href = 'https://www.helmetking.com/en';
    }

    //redirect hk domain
    if (
      data.req_url_host === 'hk.helmetking.com' ||
      data.req_url_host === 'eshop.helmetking.com'
    ) {
      window.location.href = 'https://www.helmetking.com';
    }
  }, [data.req_url_host]);

  const hasAnnouncement = data.announcement?.enabled;

  return (
    <html lang={locale.language}>
      <head>
        <Seo />
        <Meta />
        <Links />
        <script
          type="text/javascript"
          async
          src="https://preproduct.onrender.com/preproduct-embed.js"
        />
        <script
          async
          dangerouslySetInnerHTML={{
            __html: `(function() {function asyncLoad() {var urls = ["https://cdn-bundler.nice-team.net/app/js/bundler.js?shop=helmetking-0001.myshopify.com"];for (var i = 0; i < urls.length; i++) {var s = document.createElement('script');s.type = 'text/javascript';s.async = true;s.src = urls[i];var x = document.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);}};if(window.attachEvent) {window.attachEvent('onload', asyncLoad);} else {window.addEventListener('load', asyncLoad, false);}})();`,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
          }}
        />
      </head>
      <body className={hasAnnouncement ? 'has-announcement' : ''}>
        {process.env.NODE_ENV === 'development' ? null : (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=G-EKWP0X2TCX`}
            />
            <script
              async
              id="gtag-init"
              dangerouslySetInnerHTML={{
                __html: `
                console.log('gtag');
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'G-EKWP0X2TCX', {
                  page_path: window.location.pathname,
                });
              `,
              }}
            />
          </>
        )}
        <Layout
          layout={data.layout as LayoutData}
          key={`${locale.language}-${locale.country}`}
        >
          {navigation.state !== 'idle' ? (
            <LoadingScreen logo={data.logo.headerLogo} />
          ) : null}
          <Outlet />
        </Layout>
        <ScrollRestoration />
        <ExternalScripts />
        <Scripts />
      </body>
    </html>
  );
}

export function CatchBoundary() {
  const [root] = useMatches();
  const caught = useCatch();
  const isNotFound = caught.status === 404;
  const locale = root.data?.selectedLocale as I18nLocale;
  const notFoundPage = root.data?.notFoundPage as {
    page_sections_en: SanityModule[] | null;
    page_sections: SanityModule[] | null;
  };
  const pageSections =
    locale.language === 'ZH_TW'
      ? notFoundPage?.page_sections
      : notFoundPage.page_sections_en;

  return (
    <html lang={locale.language}>
      <head>
        <title>{isNotFound ? 'Not found' : 'Error'}</title>
        <Meta />
        <Links />
      </head>
      <body>
        <Layout
          layout={root?.data?.layout}
          key={`${locale.language}-${locale.country}`}
        >
          <div className="py-10">
            {pageSections?.map((module, index) => (
              <Module key={module._key} module={module} />
            ))}
          </div>
          {/* Not Found
          {isNotFound ? (
            <NotFound type={caught.data?.pageType} />
          ) : (
            <GenericError
              error={{message: `${caught.status} ${caught.data}`}}
            />
          )} */}
        </Layout>
        <Scripts />
      </body>
    </html>
  );
}

export function ErrorBoundary({error}: {error: Error}) {
  const [root] = useMatches();
  const locale = root.data?.selectedLocale as I18nLocale;
  const notFoundPage = root.data?.notFoundPage as {
    page_sections_en: SanityModule[] | null;
    page_sections: SanityModule[] | null;
  };
  const pageSections =
    locale.language === 'ZH_TW'
      ? notFoundPage?.page_sections
      : notFoundPage.page_sections_en;

  return (
    <html lang={locale.language}>
      <head>
        <title>Error</title>
        <Meta />
        <Links />
      </head>
      <body>
        <div className="py-10">
          {pageSections?.map((module, index) => (
            <Module key={module._key} module={module} />
          ))}
        </div>
        {/* <Layout layout={root?.data?.layout}>
          Error
          <GenericError error={error} />
        </Layout> */}
        <Scripts />
      </body>
    </html>
  );
}

const LAYOUT_QUERY = `#graphql
  query layoutMenus(
    $language: LanguageCode
    $headerMenuHandle: String!
    $footerMenuHandle: String!
  ) @inContext(language: $language) {
    shop {
      id
      name
      description
      primaryDomain {
        url
      }
      brand {
       logo {
         image {
          url
         }
       }
     }
    }
    headerMenu: menu(handle: $headerMenuHandle) {
      id
      items {
        ...MenuItem
        items {
          ...MenuItem
        }
      }
    }
    footerMenu: menu(handle: $footerMenuHandle) {
      id
      items {
        ...MenuItem
        items {
          ...MenuItem
        }
      }
    }
  }
  fragment MenuItem on MenuItem {
    id
    resourceId
    tags
    title
    type
    url
  }
`;

export interface LayoutData {
  headerMenu: EnhancedMenu;
  footerMenu: EnhancedMenu;
  shop: Shop;
  cart?: Promise<Cart>;
}

async function getLayoutData({storefront}: AppLoadContext) {
  const HEADER_MENU_HANDLE = 'main-menu';
  const FOOTER_MENU_HANDLE = 'footer';

  const data = await storefront.query<LayoutData>(LAYOUT_QUERY, {
    variables: {
      headerMenuHandle: HEADER_MENU_HANDLE,
      footerMenuHandle: FOOTER_MENU_HANDLE,
      language: storefront.i18n.language,
    },
  });

  invariant(data, 'No data returned from Shopify API');

  /*
    Modify specific links/routes (optional)
    @see: https://shopify.dev/api/storefront/unstable/enums/MenuItemType
    e.g here we map:
      - /blogs/news -> /news
      - /blog/news/blog-post -> /news/blog-post
      - /collections/all -> /products
  */
  const customPrefixes = {BLOG: '', CATALOG: 'products'};

  const headerMenu = data?.headerMenu
    ? parseMenu(data.headerMenu, customPrefixes)
    : undefined;

  const footerMenu = data?.footerMenu
    ? parseMenu(data.footerMenu, customPrefixes)
    : undefined;

  return {shop: data.shop, headerMenu, footerMenu};
}

const CART_QUERY = `#graphql
  query CartQuery($cartId: ID!, $country: CountryCode, $language: LanguageCode)
    @inContext(country: $country, language: $language) {
    cart(id: $cartId) {
      ...CartFragment
    }
  }

  fragment CartFragment on Cart {
    id
    checkoutUrl
    totalQuantity
    buyerIdentity {
      countryCode
      customer {
        id
        email
        firstName
        lastName
        displayName
      }
      email
      phone
    }
    lines(first: 100) {
      edges {
        node {
          id
          quantity
          attributes {
            key
            value
          }
          cost {
            totalAmount {
              amount
              currencyCode
            }
            amountPerQuantity {
              amount
              currencyCode
            }
            compareAtAmountPerQuantity {
              amount
              currencyCode
            }
          }
          merchandise {
            ... on ProductVariant {
              id
              availableForSale
              compareAtPrice {
                ...MoneyFragment
              }
              price {
                ...MoneyFragment
              }
              requiresShipping
              title
              image {
                ...ImageFragment
              }
              product {
                handle
                title
                id
              }
              selectedOptions {
                name
                value
              }
            }
          }
        }
      }
    }
    cost {
      subtotalAmount {
        ...MoneyFragment
      }
      totalAmount {
        ...MoneyFragment
      }
      totalDutyAmount {
        ...MoneyFragment
      }
      totalTaxAmount {
        ...MoneyFragment
      }
    }
    note
    attributes {
      key
      value
    }
    discountCodes {
      code
    }
  }

  fragment MoneyFragment on MoneyV2 {
    currencyCode
    amount
  }

  fragment ImageFragment on Image {
    id
    url
    altText
    width
    height
  }
`;

export async function getCart(
  {storefront}: AppLoadContext,
  cartId: string,
  url: string,
) {
  invariant(storefront, 'missing storefront client in cart query');

  const {cart} = await storefront.query<{cart?: Cart}>(CART_QUERY, {
    variables: {
      cartId,
      country: isTWSite(url) ? 'TW' : 'HK',
      language: storefront.i18n.language,
    },
    cache: storefront.CacheNone(),
  });

  return cart;
}

export async function getCustomer(
  context: AppLoadContext,
  customerAccessToken: string,
) {
  const {storefront} = context;

  const data = await storefront.query<{
    customer: Customer;
  }>(CUSTOMER_QUERY, {
    variables: {
      customerAccessToken,
      country: context.storefront.i18n.country,
      language: context.storefront.i18n.language,
    },
  });

  /**
   * If the customer failed to load, we assume their access token is invalid.
   */
  if (!data || !data.customer) {
    return undefined;
  }

  return data.customer;
}

const CUSTOMER_QUERY = `#graphql
  query CustomerDetails(
    $customerAccessToken: String!
    $country: CountryCode
    $language: LanguageCode
  ) @inContext(country: $country, language: $language) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      firstName
      lastName
      phone
      email
      defaultAddress {
        id
        formatted
        firstName
        lastName
        company
        address1
        address2
        country
        province
        city
        zip
        phone
      }
      addresses(first: 6) {
        edges {
          node {
            id
            formatted
            firstName
            lastName
            company
            address1
            address2
            country
            province
            city
            zip
            phone
          }
        }
      }
      orders(first: 250, sortKey: PROCESSED_AT, reverse: true) {
        edges {
          node {
            id
            orderNumber
            processedAt
            financialStatus
            fulfillmentStatus
            currentTotalPrice {
              amount
              currencyCode
            }
            lineItems(first: 2) {
              edges {
                node {
                  variant {
                    image {
                      url
                      altText
                      height
                      width
                    }
                  }
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;
